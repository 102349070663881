import React from "react";
import Spin from "antd/es/spin";

const FallbackLoader = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Spin size="large" />
    </div>
  );
};

export default FallbackLoader;
