import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "",
  userId: "",
  emailId: "",
  userName: "",
  primaryPhone: "",
  primaryCountryCode: "",
};

export const adminViewSlice = createSlice({
  name: "addViewState",
  initialState,
  reducers: {
    addState: (state, action) => {
      const { mode, userId, emailId, userName, primaryPhone, primaryCountryCode } = action.payload;
      if (mode !== undefined) {
        state.mode = mode;
      }
      if (userId !== undefined) {
        state.userId = userId;
      }
      if (emailId !== undefined) {
        state.emailId = emailId;
      }
      if (userName !== undefined) {
        state.userName = userName;
      }
      if (primaryPhone !== undefined) {
        state.primaryPhone = primaryPhone;
      }
      if (primaryCountryCode !== undefined) {
        state.primaryCountryCode = primaryCountryCode;
      }
    },

    resetState: (state, action) => {
      state.mode = "";
      state.userId = "";
      state.emailId = "";
      state.userName = "";
      state.primaryPhone = "";
      state.primaryCountryCode = "";
    },
  },
});

export const { addState, resetState } = adminViewSlice.actions;

export default adminViewSlice.reducer;
