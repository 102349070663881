import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user_session: "",
  access_token: "",
  id_token: "",
  refresh_token: "",
  user_name: "",
  userId: "",
  auth: false,
  role: "",
  primaryPhone: "",
  primaryCountryCode: "",
};

export const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    addAuthData: (state, action) => {
      const { user_session, access_token, id_token, refresh_token, user_name, userId, auth, role, primaryPhone, primaryCountryCode } =
        action.payload;
      // Update the state only if the property exists in the payload
      if (user_session !== undefined) {
        state.user_session = user_session;
      }
      if (access_token !== undefined) {
        state.access_token = access_token;
      }
      if (id_token !== undefined) {
        state.id_token = id_token;
      }
      if (refresh_token !== undefined) {
        state.refresh_token = refresh_token;
      }
      if (user_name !== undefined) {
        state.user_name = user_name;
      }
      if (userId !== undefined) {
        state.userId = userId;
      }
      if (primaryCountryCode !== undefined) {
        state.primaryCountryCode = primaryCountryCode;
      }
      if (primaryPhone !== undefined) {
        state.primaryPhone = primaryPhone;
      }
      if (auth !== undefined) {
        state.auth = auth;
      }
      if (role !== undefined) {
        state.role = role;
      }
    },

    resetAuthData: (state, action) => {
      state.user_session = "";
      state.access_token = "";
      state.id_token = "";
      state.refresh_token = "";
      state.user_name = "";
      state.userId = "";
      state.auth = false;
      state.role = "";
      state.primaryCountryCode = "";
      state.primaryPhone = "";
    },
  },
});

export const { addAuthData, resetAuthData } = authUserSlice.actions;

export default authUserSlice.reducer;
